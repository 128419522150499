import React, { Component } from 'react';
import NavBar from './NavBar/NavBar';
 
class App extends Component {
	constructor() {
    super();
	this.state={
		
		
	}
    
  }
  
  scrollToTop(){
	  const innerWidth = window.innerWidth;
	  window.scrollTo(0,0)
  }
  scrollToStory(){
	  const innerWidth = window.innerWidth;
	  var scroll=610*innerWidth/1080; //width original width
	  window.scrollTo(0,scroll)
  }
  scrollToVisions(){
	  const innerWidth = window.innerWidth;
	  var scroll=1175*innerWidth/1080; //width original width
	  window.scrollTo(0,scroll)
  }
  scrollToServices(){
	  const innerWidth = window.innerWidth;
	    var scroll=1860*innerWidth/1080; //width original width
	  window.scrollTo(0,scroll)
  }
  scrollToWhyPV(){
	  const innerWidth = window.innerWidth;
	   var scroll=4945*innerWidth/1080; //width original width
	  window.scrollTo(0,scroll)
  }scrollToContactUs(){
	 
	  const innerWidth = window.innerWidth;
	   var scroll=5750*innerWidth/1080; //width original width
	  window.scrollTo(0,scroll)
  }
  handleResize=()=>{
      //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
    
	this.setState({width: window.innerWidth})
    
  }
  componentDidMount(){
	 // console.log("test")
    
  window.addEventListener('resize', this.handleResize)
  }
  render() {
	   const innerWidth = window.innerWidth;
	   return (
	
      <div className='full-page-image'>
	  {innerWidth>=650 && <NavBar scrollToTop={this.scrollToTop} scrollToStory={this.scrollToStory} scrollToVisions={this.scrollToVisions} scrollToServices={this.scrollToServices} scrollToWhyPV={this.scrollToWhyPV} scrollToContactUs={this.scrollToContactUs}/>}
	  
     <img src={ require('./ProVisionsWebsitejpg.jpg') } alt="ProVisions"/>
	 
      </div>
  )
	 
	 }
	 
}

export default App;