import React from 'react';
import {Link} from 'react-router-dom';
import { Component } from 'react';
class NavBar extends Component {
	constructor() {
    super();
	this.state={
		
		
	}
    
  }
  
  render() {
	   return (
	
    <nav  style={{backgroundColor:'rgba(0,0,0,0.5)', width:'80%',marginLeft:'10%',flexDirection:'row',flexWrap:'nowrap'}}  className="navbar navbar-dark fixed-top">
      <Link className="navbar-brand" to='/' onClick={this.props.scrollToTop}>
        ProVisions
      </Link>
	  <Link className="navbar-nav text-white small" to='/' onClick={this.props.scrollToStory}>
        Our Story
      </Link>
	    <Link className="navbar-nav text-white small" to='/' onClick={this.props.scrollToVisions}>
        Values
      </Link>
	      <Link className="navbar-nav text-white small" to='/' onClick={this.props.scrollToServices}>
        Services
      </Link>
	   <Link className="navbar-nav text-white small" to='/' onClick={this.props.scrollToWhyPV}>
        WhyPV
      </Link>
	   <Link className="navbar-nav text-white small" to='/' onClick={this.props.scrollToContactUs}>
        Contact Us
      </Link>
    </nav>
	
  )
	 
	 }
	 
}

export default NavBar;
